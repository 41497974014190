<template>
  <div class="news-detail">
  <!--面包屑区域-->
  <el-breadcrumb separator="/">
    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item :to="{ path: '/newscenter' }">新闻中心</el-breadcrumb-item>
    <el-breadcrumb-item>新闻详情</el-breadcrumb-item>
  </el-breadcrumb>
<!--  内容区域-->
  <div class="content">
<!--    左侧内容区域-->
    <div class="content-left">
      <!-- <div class="title">{{ detail.title }}</div>
      <div class="timefrom">
        <span>{{ detail.time }}</span>
        <span>{{ detail.from }}</span>
      </div> -->
<!--      <div class="content-detail">{{ detail.context }}</div>-->
      <div v-html="detail" class="width-content ql-editor"></div>
      <router-link to="/newscenter"
                   class="back-to-center">返回新闻中心</router-link>
    </div>
<!--    右侧更多新闻区域-->
    <!-- <div class="content-right">
      <span class="more-title">更多新闻</span>
      <div class="more-news">
        <div class="more-news-item"
             v-for="items in morenews"
             :key="items.id"
             @click="toDetail(items.id)">
          <div>{{ items.title }}</div>
        </div>
      </div>
    </div> -->
  </div>
</div>
</template>

<script>
import { request } from '@/network';

export default {
  name: 'indexNewsDetail',
  data () {
    return {
      detail: '',
      morenews: [
        {
          id: 4,
          title: '用户协议',
          author: '',
          createtime: '2021-04-26'
        }
      ],
      detailV: '',
      detailId: 0
    };
  },
  created () {
    this.detailId = this.$route.query.id;
    this.getNewsDetail().then(() => { this.getMoreNews(); });
  },
  methods: {
    async getNewsDetail () {
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/index/getNewsById',
        params: {
          id: this.detailId
        }
      });
      if (res.code !== 200) return this.$message.error(res.message);
      this.detail = res.data;
    },
    async getMoreNews () {
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/index/getMoreNews'
      });
      if (res.code !== 200) return this.$message.error(res.message);
      this.morenews = res.data;
    },
    toDetail (id) {
      this.$router.push({
        path: '/newsdetail',
        query: {
          id: id
        }
      });
      window.location.reload();
    }
  }
};
</script>

<style>
@import '~@/assets/pc/style/quill.bubble.css';
@import '~@/assets/pc/style/quill.core.css';
@import '~@/assets/pc/style/quill.snow.css';
</style>

<style scoped lang="less">
.width-content {
  line-height: 27px;
}
.news-detail {
  background-color: #fff;
  padding: 45px 0px 59px;
}
.el-breadcrumb {
  padding-bottom: 32px;
}
.content {
  display: flex;
  justify-content: center;
}
.content .content-left {
  width: 1200px;
}
.content .content-left .title {
  padding-bottom: 20px;
  font-size: 28px;
  color: #000000;
  border-bottom: 2px solid #000000;
}
.content .content-left .timefrom {
  margin-top: 19px;
  font-size: 14px;
  color: #8D8D8D;
}
.content .content-left .timefrom span:last-child {
  margin-left: 28px;
}
.content .content-left .content-detail {
  margin-top: 43px;
}
.content .content-left .back-to-center {
  display: inline-block;
  // margin-top: 83px;
  color: #156ED0;
  font-size: 18px;
}
.content .content-left .width-content /deep/img{
  max-width:100%;
}
.content-right {
  margin-top: 160px;
  margin-left: 45px;
  width: 229.5px;
}
.content-right .more-title {
  font-size: 18px;
  color: #585858;
}
.content-right .more-news {
  margin-top: 23px;
}
.content-right .more-news-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 38px;
  height: 48.5px;
  width: 100%;
  cursor: pointer;
}
.content-right .more-news-item img{
  height: 48.5px;
  width: 64.5px;
}
.content-right .more-news-item div {
  margin-top: 8px;
  height: 33px;
  width: 150px;
  font-size: 14px;
  color: #585858;
  overflow: hidden;
  text-overflow: ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2;
}
</style>
