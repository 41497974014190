import { render, staticRenderFns } from "./indexNewsDetail.vue?vue&type=template&id=8c7d3022&scoped=true&"
import script from "./indexNewsDetail.vue?vue&type=script&lang=js&"
export * from "./indexNewsDetail.vue?vue&type=script&lang=js&"
import style0 from "./indexNewsDetail.vue?vue&type=style&index=0&lang=css&"
import style1 from "./indexNewsDetail.vue?vue&type=style&index=1&id=8c7d3022&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c7d3022",
  null
  
)

export default component.exports